const nunitoBold = require("./Inter-Black.ttf")
const nunitoLight = require("./Inter-Light.ttf")
const nunitoRegular = require("./Inter-Regular.ttf")
const nunitoSemiBold = require("./Inter-Bold.ttf")

export const Fonts = {
    bold: nunitoBold,
    light: nunitoLight,
    regular: nunitoRegular,
    semiBold: nunitoSemiBold
}
